export function initializeVideoHandler() {
    const videoElement = document.querySelector(".olaf-video");
    if (!videoElement) return;

    const olafContainer = document.querySelector(".olaf-container");
    const fallbackImage = olafContainer.querySelector("img");
    const videoSourceMP4 = document.getElementById("videoSourceMP4");
    const videoSourceWEBM = document.getElementById("videoSourceWEBM");

    setupFallback(videoElement, fallbackImage);
    setupVideoSources(videoElement, videoSourceMP4, videoSourceWEBM, fallbackImage);

    videoElement.addEventListener("loadeddata", () => {
        updateAlignment(videoElement, olafContainer);
        videoElement.style.opacity = 1; // Fade in effect
    });
}

function setupFallback(videoElement, fallbackImage) {
    let errorCount = 0;
    const totalSources = videoElement.querySelectorAll("source").length;

    videoElement.querySelectorAll("source").forEach((source) => {
        source.addEventListener("error", () => {
            errorCount += 1;
            if (errorCount === totalSources) {
                showFallback(videoElement, fallbackImage);
            }
        });
    });

    videoElement.addEventListener("error", () => {
        showFallback(videoElement, fallbackImage);
    });

    videoElement.addEventListener("loadeddata", () => {
        if (videoElement.readyState < 3) {
            // If the video can't play smoothly, fallback
            showFallback(videoElement, fallbackImage);
        }
    });
}

function showFallback(videoElement, fallbackImage) {
    console.log("Showing fallback image...");
    videoElement.style.display = "none"; // Hide video
    if (fallbackImage) {
        fallbackImage.style.display = "block"; // Show fallback image
        fallbackImage.style.opacity = 1;
    } else {
        console.log("Fallback image element not found!");
    }
}

function setupVideoSources(videoElement, videoSourceMP4, videoSourceWEBM, fallbackImage) {
    
    const isSmallScreen = window.innerWidth < 992; // Detect small screens
    const userAgent = navigator.userAgent.toLowerCase();

    // Detect OS and browser
    const osInfo = {
        isMac: userAgent.includes("mac"),
        isWindows: userAgent.includes("windows"),
        isFirefox: userAgent.includes("firefox"),
        isAndroid: userAgent.includes("android"),
        isChrome: userAgent.includes("chrome") && !userAgent.includes("firefox"),
    };

    function setVideoSourceByOS() {
        // Define video paths
        const videoPathWEBM = isSmallScreen
            ? "video/olaf_portrait_small-webm.webm"
            : "video/olaf_portrait-webm.webm";
        const videoPathMP4 = isSmallScreen
            ? "video/olaf_portrait_small-hevc.mp4"
            : "video/olaf_portrait-hevc.mp4";


        // Use transparent WebM for Android Chrome
        if (osInfo.isAndroid && osInfo.isChrome) {
            videoSourceWEBM.src = videoPathWEBM;
            videoSourceMP4.removeAttribute("src");
            videoElement.load();
            return; // Exit early since we set the correct source
        }

        if (osInfo.isFirefox || osInfo.isWindows) {
            videoSourceWEBM.src = videoPathWEBM;
            videoSourceMP4.removeAttribute("src");
        } else if (osInfo.isMac) {
            videoSourceMP4.src = videoPathMP4;
            videoSourceWEBM.removeAttribute("src");
        } else {
            videoSourceWEBM.src = videoPathWEBM;
            videoSourceMP4.src = videoPathMP4;
        }

        // Reload video with updated sources
        videoElement.load();
    }


    window.addEventListener("resize", throttle(() => {
        setVideoSourceByOS();
    }, 200));

    setVideoSourceByOS();
}


// function setupVideoSources(videoElement, videoSourceMP4, videoSourceWEBM) {
//     const isSmallScreen = window.innerWidth < 992;

//     const userAgent = navigator.userAgent.toLowerCase();
//     const osInfo = {
//         isMac: userAgent.includes("mac"),
//         isWindows: userAgent.includes("windows"),
//         isFirefox: userAgent.includes("firefox"),
//         isAndroid: userAgent.includes("android"),
//         isChrome: userAgent.includes("chrome") && !userAgent.includes("firefox"),
//     };

//     function setVideoSourceByOS() {
//         const videoPathWEBM = isSmallScreen
//             ? "video/olaf_portrait_small-webm.webm"
//             : "video/olaf_portrait-webm.webm";
//         const videoPathMP4 = isSmallScreen
//             ? "video/olaf_portrait_small-hevc.mp4"
//             : "video/olaf_portrait-hevc.mp4";

//         videoSourceWEBM.src = osInfo.isFirefox || osInfo.isWindows ? videoPathWEBM : "";
//         videoSourceMP4.src = osInfo.isMac ? videoPathMP4 : "";

//         videoElement.load();
//     }

//     window.addEventListener("resize", throttle(() => {
//         setVideoSourceByOS();
//     }, 200));

//     setVideoSourceByOS();
// }

function updateAlignment(videoElement, olafContainer) {
    const isVideoTaller = videoElement.offsetHeight > window.innerHeight;
    olafContainer.style.alignItems = isVideoTaller ? "center" : "flex-end";
}

function throttle(fn, limit) {
    let lastCall = 0;
    return function (...args) {
        const now = Date.now();
        if (now - lastCall >= limit) {
            lastCall = now;
            fn(...args);
        }
    };
}
