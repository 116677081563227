import SimpleParallax from "simple-parallax-js/vanilla";

let parallaxInstances = []; // Keep track of current parallax instances
const breakpoint = 992; // 62rem in pixels

// Function to initialize parallax for a specific layer
function setupParallax(layerClass, options) {
    const images = document.getElementsByClassName(layerClass);
    if (images.length > 0) {
        console.log(`Initializing parallax for: ${layerClass}`);
        const instance = new SimpleParallax(images, options);
        parallaxInstances.push(instance); // Store instance for later reset
    }
}

// Function to destroy existing parallax instances
function destroyParallax() {
    if (parallaxInstances.length > 0) {
        parallaxInstances.forEach(instance => instance.destroy());
        parallaxInstances = []; // Clear the array after destroying instances
        console.log("Parallax instances destroyed.");
    }
}

// Function to swap images based on viewport size
function swapParallaxImages(isLargeScreen) {
    const layers = document.querySelectorAll('.layer-back, .layer-middle, .layer-front');
    layers.forEach(layer => {
        const largeSrc = layer.dataset.largeSrc; // Add a `data-large-src` attribute in HTML
        const smallSrc = layer.dataset.smallSrc; // Add a `data-small-src` attribute in HTML

        // Set the initial src based on the screen size
        layer.src = isLargeScreen ? largeSrc : smallSrc;

        console.log(`Swapped image for ${layer.className} to: ${layer.src}`);
    });
}

// Function to initialize all parallax layers
export function initializeParallax() {
    // Determine whether the screen is large or small
    const isLargeScreen = window.innerWidth >= breakpoint;

    // Swap images based on the current screen size
    swapParallaxImages(isLargeScreen);

    // Define the layers and their options
    const layers = [
        { className: 'layer-back', options: { delay: 0.5, scale: 1.2, overflow: true, orientation: 'up', transition: 'cubic-bezier(0,0,0,1)' } },
        { className: 'layer-middle', options: { delay: 0.5, scale: 1.4, overflow: true, orientation: 'up', transition: 'cubic-bezier(0,0,0,1)' } },
        { className: 'layer-front', options: { delay: 0.5, scale: 1.6, overflow: true, orientation: 'up', transition: 'cubic-bezier(0,0,0,1)' } },
    ];

    // Destroy existing parallax instances
    destroyParallax();

    // Initialize parallax for each layer
    layers.forEach(layer => setupParallax(layer.className, layer.options));
}

// Function to handle viewport resizing
function handleResize() {
    let isLargeScreen = window.innerWidth >= breakpoint;

    // Swap images and reinitialize parallax when crossing the breakpoint
    return function () {
        const newIsLargeScreen = window.innerWidth >= breakpoint;

        if (newIsLargeScreen !== isLargeScreen) {
            isLargeScreen = newIsLargeScreen;

            // Swap images based on new screen size
            swapParallaxImages(isLargeScreen);

            // Reinitialize parallax with the new images
            initializeParallax();
        }
    };
}

// Attach the resize handler
const resizeHandler = handleResize();
window.addEventListener('resize', resizeHandler);

// Initialize parallax on page load
initializeParallax();
