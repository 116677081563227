import { initializeMixBlendModeCheck } from './lights.js';
import { setupReturnLink, setupPanelSlider, setupEntryForm } from './present.js';
import { startSnow } from './snow.js';
import { initializeVideoHandler } from './olaf-video.js';
import { initializeParallax } from './parallax.js';

$(function() {

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Loader
    $('#app').addClass('load-in');

    // OPEN/CLOSE NAV
    $('#rulebookToggle').click(function() {
        $('#app, body').toggleClass('rulebook-open');
    });

    // RULEBOOK TABS
    $('.tabs-stage div').hide();
    $('.tabs-stage div:first').show();
    $('.tabs-nav li:first').addClass('tab-active');
    $('.tabs-nav a').on('click', function(event) {
        event.preventDefault();
        $('.tabs-nav li').removeClass('tab-active');
        $(this).parent().addClass('tab-active');
        $('.tabs-stage div').hide();
        $($(this).attr('href')).show();
    });

    // MODALS
    $('.modal-button').click(function() {
        var buttonId = $(this).attr('id');
        $('#modal-container').removeAttr('class').addClass(buttonId);
        $('body').addClass('modal-active');
    })

    $('#modal-close').click(function() {
        $('#modal-container').addClass('out');
        $('body').removeClass('modal-active');
    });

    $('#btn-play').on('click', function(e) {
        $('.form-hidden').submit();
    });

    // when items are clicked set the hidden form value and post to present.php
    $('.present-item.past, .present-item.today').on('click', (e) => {
        // set the value of hidden input to the selected present and submit form
        let n = $(e.currentTarget).find("figure").html();
        $('#form-next input[name=present]').val(n);
        $('#form-next').submit();
    });

    // Add enter-form submit listener
    $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);

    function onWinnerFormSubmit(e) {

        e.preventDefault();

        // clear any previous error messages
        $('label.error').html('');

        let entryID = $("#winner-form input[name='entryID']").val().trim(),
            firstName = $("#winner-form input[name='firstName']").val().trim(),
            lastName = $("#winner-form input[name='lastName']").val().trim(),
            emailAddress = $("#winner-form input[name='emailAddress']").val().trim(),
            abtaNumber = $("#winner-form input[name='abtaNumber']").val().trim(),
            address1 = $("#winner-form input[name='address1']").val().trim(),
            address2 = $("#winner-form input[name='address2']").val().trim(),
            address3 = $("#winner-form input[name='address3']").val().trim(),
            postCode = $("#winner-form input[name='postCode']").val().trim(),
            confirmAge = $("#winner-form input[name='confirmAge']").is(':checked'),
            genericFailMsg = 'Error. Encountered an error submitting your details. Please try again later.';

        var posting = $.post('./php/process-winner.php', {
                entryID: entryID,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                abtaNumber: abtaNumber,
                address1: address1,
                address2: address2,
                address3: address3,
                postCode: postCode,
                confirmAge: confirmAge
            })
            .done(function(response) {

                console.log(response);

                if (response.trim().length > 0) {

                    if (response == 'Success') {

                        console.log('Success');
                        document.getElementById("winner-form").style.display = "none";
                        document.getElementById("entrySubmitted").style.display = "block";
                    } else {
                        var parsedResponse = "";

                        try {
                            parsedResponse = JSON.parse(response);

                            if (typeof parsedResponse === 'object' || parsedResponse instanceof Object) {

                                console.log("DISPLAY ERRORS")
                                    // validation error
                                for (const prop in parsedResponse) {
                                    console.log(prop + ":  " + parsedResponse[prop]);
                                    $('label.error[for="' + prop + '"]').html(parsedResponse[prop]);
                                }
                                // allow user to resubmit form 
                                $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);
                            } else {
                                console.log('Response Error: Parsed json not an object.');
                                $('label.error[for="submission-error"]').html(genericFailMsg);
                                // allow user to resubmit form 
                                $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);
                            }
                        } catch (error) {
                            // unable to parse response
                            console.log(error);
                            $('label.error[for="submission-error"]').html(genericFailMsg);
                            // allow user to resubmit form 
                            $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);
                        }
                    }
                } else {
                    // unable to parse response
                    console.log('Nothing returned from php process');
                    $('label.error[for="submission-error"]').html(genericFailMsg);
                    // allow user to resubmit form 
                    $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);
                }
            })
            .fail(function(error) {
                // possible internal server error
                console.log(error);
                $('label.error[for="submission-error"]').html(genericFailMsg);
                // allow user to resubmit form 
                $('#winner-form .submit-btn').one('click', onWinnerFormSubmit);
            });

    }

    startSnow();
    initializeParallax();
    // return to calendar from present
    setupReturnLink();
    // init slick slider
    setupPanelSlider();
    // init entry form
    setupEntryForm();
    // init olaf video
    initializeVideoHandler();
});

$(window).on('load', function() {
    console.log('All resources, including images and iframes, are fully loaded.');
});