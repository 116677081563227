import $ from 'jquery'; // Ensure jQuery is installed via npm
import 'slick-carousel'; // Import Slick carousel (ensure it's installed via npm)

// Function to handle return link click
export function setupReturnLink() {
    $(".return-link").on('click', function (e) {
        e.preventDefault();
        $("#form-next").attr('action', 'calendar.php');
        $("#form-next").submit();
    });
}

// Function to initialize the panel slider
export function setupPanelSlider() {
    $('#panel-slider').slick({
        infinite: false,
        fade: true,
        speed: 750,
        appendArrows: '#panel-controls',
        prevArrow: '<button type="button" class="slick-prev">Previous</button>',
        nextArrow: '<button type="button" class="slick-next">Next</button>',
        responsive: [{
            breakpoint: 1200,
            settings: {
                adaptiveHeight: true,
            },
        }],
    });

    // Add 3D rotate effect
    $('#panel-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        const targetDeg = nextSlide * 90;
        $('.view-3d .present-3d').css('transform', `rotateY(${targetDeg}deg)`);
    });

    // Show the panel-container once Slick is set up
    $('#panel-slider').removeClass('invisible');
}

// Function to uncheck all radio inputs initially
export function resetRadioInputs() {
    $("input:radio").each(function () {
        this.checked = false;
    });
}

// Function to handle the entry form submission
export function setupEntryForm() {
    $('#entry-form .submit-btn').one('click', onEntryFormSubmit);

    function onEntryFormSubmit(e) {
        e.preventDefault();

        // Clear any previous error messages
        $('label.error').html('');

        // Get form data
        const formData = {
            firstName: $(".entry-form input[name='firstName']").val().trim(),
            lastName: $(".entry-form input[name='lastName']").val().trim(),
            emailAddress: $(".entry-form input[name='emailAddress']").val().trim(),
            abtaNumber: $(".entry-form input[name='abtaNumber']").val().trim(),
            isROI: $(".entry-form input[name='isROI']").is(':checked'),
            confirmAge: $(".entry-form input[name='confirmAge']").is(':checked'),
            dayOfComp: $(".entry-form input[name='dayOfComp']").val().trim(),
        };

        const genericFailMsg = 'Error. Encountered an error submitting your details. Please try again later.';

        // Submit form data via AJAX
        $.post('./php/process-entry.php', formData)
            .done((response) => {
                try {
                    const parsedResponse = JSON.parse(response);
                    if (parsedResponse?.entryID) {
                        $('#form-next input[name=entryID]').val(parsedResponse.entryID);
                        $("#form-next").submit();
                    } else {
                        for (const prop in parsedResponse) {
                            $(`label.error[for="${prop}"]`).html(parsedResponse[prop]);
                        }
                        $('#entry-form .submit-btn').one('click', onEntryFormSubmit);
                    }
                } catch (error) {
                    console.error('JSON Parsing Error:', error);
                    $('label.error[for="submission-error"]').html(genericFailMsg);
                    $('#entry-form .submit-btn').one('click', onEntryFormSubmit);
                }
            })
            .fail((error) => {
                console.error('Server Error:', error);
                $('label.error[for="submission-error"]').html(genericFailMsg);
                $('#entry-form .submit-btn').one('click', onEntryFormSubmit);
            });
    }
}
