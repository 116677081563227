export function getSnowCount() {
    return window.innerWidth < 768 ? 150 : 400; // Set to 150 for mobile screens, 400 for larger screens
}

export function startSnow() {

    const canvas = document.getElementById("snow-canvas");
    const ctx = canvas.getContext("2d");

    function random(factor = 1) {
        return Math.random() * factor;
    }

    class Circle {
        constructor(ctx) {
            this.ctx = ctx;
            this.reset();
        }

        draw() {
            this.ctx.beginPath();
            this.ctx.shadowBlur = 10;
            this.ctx.shadowColor = `rgba(255, 255, 255, ${this.alpha})`;
            this.ctx.fillStyle = `rgba(255, 255, 255, ${this.alpha})`;
            this.ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
            this.ctx.fill();
            this.ctx.closePath();
        }

        reset() {
            this.radius = random(5);
            this.alpha = Math.random() * 0.9 + 0.1;
            this.x = random(canvas.width);
            this.y = random(canvas.height);
            this.vx = (Math.random() - 0.5) * 0.4;
            this.vy = random(0.25) * this.radius * 0.5;
        }
    }

    function setCanvasSize() {
        if (window.visualViewport) {
            canvas.width = window.visualViewport.width;
            canvas.height = window.visualViewport.height;
        } else {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        }
    }


    setCanvasSize();
    let SNOW_COUNT = getSnowCount();
    let circles = Array.from({ length: SNOW_COUNT }, () => new Circle(ctx));

    function clearCanvas() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    function animate() {
        clearCanvas();

        circles.forEach((circle) => {
            if (circle.x < 0 || circle.x > canvas.width || circle.y > canvas.height) {
                circle.reset();
            }
            circle.x += circle.vx;
            circle.y += circle.vy;
            circle.draw();
        });

        requestAnimationFrame(animate);
    }

    // Initialize canvas size and animation
    animate();

    // Debounced resize handling
    let resizeTimeout;
    window.addEventListener("resize", () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
            setCanvasSize();
            SNOW_COUNT = getSnowCount();
            circles = Array.from({ length: SNOW_COUNT }, () => new Circle(ctx));
        }, 100);
    });
}
